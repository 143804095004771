import React from 'react';
import Layout from 'components/layout';
import Seo from 'components/seo';
import {
  ArticleCategory,
  ArticleData,
  GatsbyResultSet,
  HomePageData,
  JourneyStep,
} from 'models';
import { Typography } from 'antd';
import { graphql, Link, PageProps } from 'gatsby';
import Articles from '@/components/articles';

interface BlogCategoryPageProps {
  articles: GatsbyResultSet<ArticleData>;
  categories: GatsbyResultSet<ArticleCategory>;
  category: ArticleCategory;
  homepage: HomePageData;
  journeySteps: JourneyStep[];
}
const BlogCategoryPage: React.FC<PageProps<BlogCategoryPageProps>> = ({
  data,
}) => {
  const [articles] = React.useState<ArticleData[]>(
    data.articles.edges.map((x) => x.node),
  );
  const [categories] = React.useState<ArticleCategory[]>(
    data.categories.edges.map((x) => x.node),
  );
  return (
    <Layout expand={false}>
      <Seo seo={data.category.seo} />
      <Typography.Text
        type="secondary"
        style={{ marginBottom: `15px`, fontSize: `15px` }}
      >
        <Link to="/blog" style={{ textTransform: `capitalize` }}>
          Blog
        </Link>
        {` > `}
        <Link
          to={`/blog/category/${data.category.slug}`}
          style={{ textTransform: `capitalize` }}
        >
          {data.category.name}
        </Link>
      </Typography.Text>
      <Typography.Title
        level={4}
        style={{ textTransform: `capitalize`, marginTop: `10px` }}
      >
        {data.category.name}
      </Typography.Title>
      <Typography.Paragraph style={{ marginBottom: `30px` }}>
        {data.category.seo.metaDescription}
      </Typography.Paragraph>
      <Articles articles={articles} categories={categories} />
    </Layout>
  );
};

export const query = graphql`
  query BlogCategoryPageQuery($slug: String!) {
    category: strapiCategory(slug: { eq: $slug }) {
      name
      slug
      seo {
        metaDescription
        id
        metaTitle
        ogType
        shareImage {
          url
        }
      }
    }
    categories: allStrapiCategory {
      edges {
        node {
          name
          slug
        }
      }
    }
    articles: allStrapiArticle(
      sort: { order: DESC, fields: published_at }
      filter: { category: { slug: { eq: $slug } } }
    ) {
      edges {
        node {
          published_at(locale: "fr", formatString: "DD MMMM YYYY")
          strapiId
          slug
          title
          category {
            name
          }
          description
          image {
            localFile {
              url
              childImageSharp {
                gatsbyImageData(
                  height: 450
                  layout: FULL_WIDTH
                  placeholder: TRACED_SVG
                  formats: [AUTO, WEBP]
                  transformOptions: { fit: COVER, cropFocus: ATTENTION }
                )
              }
            }
          }
        }
      }
    }
  }
`;
export default BlogCategoryPage;
